import * as actionTypes from "./actions";

export const setLogin = (
  logged_in,
  user,
  menus,
  insinfo,
  doctors,
  marketers,
  tests
) => {
  return {
    type: actionTypes.SET_LOGIN,
    logged_in,
    user,
    menus,
    insinfo,
    doctors,
    marketers,
    tests,
  };
};

export const setNavMenu = (nav_menu) => {
  return {
    type: actionTypes.SET_NAV_MENU,
    nav_menu,
  };
};

export const setLogout = (logged_in) => {
  return {
    type: actionTypes.SET_LOGOUT,
    logged_in: logged_in,
  };
};

export const setDoctor = (doctor) => {
  return {
    type: actionTypes.SET_DOCTOR,
    doctor,
  };
};

export const setMarketer = (marketer) => {
  return {
    type: actionTypes.SET_MARKETER,
    marketer,
  };
};

export const setUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    user,
  };
};

export const setInsinfo = (insinfo) => {
  return {
    type: actionTypes.SET_INSINFO,
    insinfo,
  };
};
